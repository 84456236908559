import React from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'

import logoSvg from '../../assets/logo/logo.svg'
import logoPng from '../../assets/logo/silicane.png'
import photo from '../../assets/images/sander_nagtegaal.jpg'

class Index extends React.Component {
  render() {
    const {
      name,
      description,
      keywords,
      slug,
      posts,
      type,
    } = this.props

    const {
      title,
      siteUrl,
      subtitle,
    } = this.props.data.site.siteMetadata

    const SEO_DATA = {
      metaDescription: description || subtitle,
      metaTitle: name || title,
      url: slug || siteUrl,
      author: 'Sander Nagtegaal',
      keywords: keywords || ['Silicane', 'Sander Nagtegaal', 'Unless', 'Peecho'],
      twitter: {
        id: '@silicane_com',
        img: photo,
      },
      facebook: {
        id: 'centrical',
        img: photo,
      },
    }

    const Json = {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      inLanguage: 'English',
      name: SEO_DATA.metaTitle,
      description: SEO_DATA.metaDescription,
      author: {
        '@type': 'Person',
        name: SEO_DATA.author,
        image: photo,
        givenName: 'Sander',
        familyName: 'Nagtegaal',
        sameAs: [
          'http://nl.linkedin.com/in/centrical',
          'https://www.facebook.com/centrical',
          'https://twitter.com/silicane_com',
        ],
      },
      isPartOf: {
        '@type': 'WebSite',
        '@id': 'https://silicane.com/',
        name: 'Silicane',
      },
    }

    const postsJson = []
    if (type !== 'page') {
      posts.forEach(post => {
        postsJson.push({
          '@type': 'BlogPosting',
          '@id': post.node.fields.slug,
          url: post.node.fields.slug,
          name: post.node.frontmatter.title,
          author: SEO_DATA.author,
          publisher: {
            '@type': 'Organization',
            name: 'Centrical BV',
            url: 'https://silicane.com/',
            logo: {
              '@type': 'ImageObject',
              url: logoPng,
            },
          },
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': siteUrl + post.node.fields.slug,
          },
          image: photo,
          headline: post.node.frontmatter.description,
          datePublished: moment(post.node.frontmatter.date)
            .format('YYYY-MM-DD'),
          dateModified: moment()
            .format('YYYY-MM-DD'),
        })
      })

      Json.hasPart = {
        '@type': 'Blog',
        blogPost: postsJson,
      }
    }

    return (
      <Helmet>
        <link rel="icon" href={logoSvg} sizes="any" type="image/svg+xml" />
        <meta property="fb:app_id" content={SEO_DATA.facebook.id} />
        <meta property="og:title" content={SEO_DATA.metaTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={SEO_DATA.url} />
        <meta property="og:image" content={SEO_DATA.facebook.img} />
        <meta property="og:description" content={SEO_DATA.metaDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={SEO_DATA.twitter.id} />
        <meta name="twitter:site" content={SEO_DATA.twitter.id} />
        <meta name="twitter:title" content={SEO_DATA.metaTitle} />
        <meta name="twitter:description" content={SEO_DATA.metaDescription} />
        <meta name="twitter:domain" content={SEO_DATA.url} />
        <meta name="twitter:image:src" content={SEO_DATA.img} />
        <meta name="description" content={SEO_DATA.metaDescription} />
        <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
        <meta name="author" content={SEO_DATA.author} />
        <title>{SEO_DATA.metaTitle}</title>
        <script type="application/ld+json">
          {JSON.stringify(Json)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              '@context': 'http://schema.org',
              '@type': 'SiteNavigationElement',
              url: ['/', '/about'],
            }
          )}
        </script>
        <html lang="en" />
      </Helmet>
    )
  }
}

export default Index
